export const appName: string = 'Micaelle Marques'
export const appDescription: string = 'Clínica de Estética & SPA'
export const appKeywords: string = 'micaelle, marques, michelle, clinica, clínica, estetica, estética, spa, massagem, massagens, especial, erotica, erótica, sensual, prostatica, prostática, quatro mãos, 4 mãos, pedras, pedras quentes, bambuteria, tantrica, tântrica, inversa, nuru'

export const appLocalAddress: string = `Rua C-131, <br />Quadra 251 Lote 13/15 Casa 632<br />CEP: 74255-240<br />Jardim América - Goiânia - Goiás`
export const appTelePhone: number = 556236374234
export const appWhatsApp: number = 5562982289953
export const appEmail: string = 'contato@micaellemassagem.com.br'
export const appTelePhoneURL: string = `tel:${appTelePhone}`
export const appEmailURL: string = `mail:${appEmail}`
export const appWhatsAppURL: string = `https://wa.me/${appWhatsApp}?text=Ol%C3%A1%2C+vim+pelo+site+gostaria+de+saber+mais+a+respeito.`
export const appOpeningHours: string = 'Horário de atendimento:<br />Segunda a Sexta: 10h00 às 19h00<br />Sábado: 10h00 às 15h00'
