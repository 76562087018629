export type MasseurList = {
	file: string
	name: string
	modal: boolean
}

export const useMasseurList = (): MasseurList[] => {
	return [
		{
			file: 'micaele_2024-06-20',
			name: 'Massoterapeuta Micaele',
			modal: true
		},
		{
			file: 'catarina_2024-06-20',
			name: 'Massoterapeuta Catarina',
			modal: true
		},
		{
			file: 'agatha_2024-08-08',
			name: 'Massoterapeuta Ágatha',
			modal: true
		}
	]
}
