
import * as cloudflareRuntime$C9OxswrNvv from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as ipxStaticRuntime$EE6En0vI5B from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$C9OxswrNvv, defaults: {"baseURL":"https://cdn.micaellemassagem.com.br"} },
  ['ipxStatic']: { provider: ipxStaticRuntime$EE6En0vI5B, defaults: {} }
}
        